"use strict";

import '../util/jqextend';

class AbstractSelect {
    constructor (container) {
        this.el_cont = $(container);
        this.el_sel = this.el_cont.find("select");
    }
}

class VanillaSelect extends AbstractSelect {
    constructor (container) {
        super(container);
        if (this.el_sel.val().length > 0)
            this.el_cont.addClass("non-empty");

        this.el_sel.change( () => {
            if (this.el_sel.val().length === 0)
                this.el_cont.removeClass("non-empty");
            else
                this.el_cont.addClass("non-empty");
        });
    }
}

class CustomSelect extends AbstractSelect {
    constructor (container) {
        super(container);
        if (!this.el_sel) return;
        
        let id = this.el_sel.attr("id");
        let name = this.el_sel.attr("name");
        let required = this.el_sel.attr("required");
        let autofocus = this.el_sel.attr("autofocus");
        let hint = this.el_cont.find("label").text();

        this.options = [];
        this.selected = -1;

        this.el_sel.find("option").each( (i, opt) => {
            if (!$(opt).val() || !$(opt).text()) return;
            this.options.push({
                value: $(opt).val(),
                text: $(opt).text(),
                data: $(opt).data(),
                selected: $(opt).is(':selected')
            });
        });
        
        this.errors = this.el_cont.find("ul.errorlist");
        this.el_cont.html("");
        
        // noinspection JSUnresolvedFunction
        this.el_box = this.el_cont.appendNew("div", {"class": "input-select-box"});
        this.el_text = this.el_box.appendNew("input", {
            "type": "text",
            "id": id + "_text",
            "name": name + "_text",
            "required": required,
            "autocomplete": "off",
            "readonly": true});
        if (autofocus) this.el_text.focus();
        
        // this.el_test

        console.log('HEHEHHEEHEHHEH')
        this.el_list = this.el_box.appendNew("ul", {"class": "input-select-list"}); // noinspection JSUnresolvedFunction
        this.el_hidden = this.el_cont.appendNew("input", {"type": "hidden", "id": id, "name": name});

        // noinspection JSUnresolvedFunction
        this.el_cont.appendNew("label", {"for": id + "_text"}, hint); // noinspection JSUnresolvedFunction
        this.el_arrow = this.el_cont.appendNew("div", {"class": "input-select-arrow"});

        this.el_cont.append(this.errors);

        this.setupDefault();
        this.buildList();
        this.el_list.on("click", (event) => this.on_select($(event.target).closest('li')) );
        
        this.el_text.on("input", () =>  this.on_input() );
        
        this.el_arrow.on("mousedown", () => {this._md_state = this.isListVisible();} );
        this.el_arrow.on("click", () => this.on_arrow() );
        
        this.el_text.on("mousedown", () => {this._md_state = this.isListVisible();} );
        this.el_text.on("click", () => this.on_click() );
        this.el_text.on("focus", () => {
            this.el_cont.addClass("focused");
            this.showList();
            this.saved_value = null;
            this.on_focus();
        });
        this.el_text.on("blur", (event) => {
            this.el_cont.removeClass("focused");
            if (event.relatedTarget && !$.contains(this.el_cont, event.relatedTarget)) this.hideList();
            this.on_blur();
        });
        
        this.el_text.on("keydown", (event) => {
            if (event.which === 38) {  // Up
                event.preventDefault();
                this.on_shift(-1);
            }
            if (event.which === 40) {  // Down
                event.preventDefault();
                this.on_shift(+1);
            }
            if (event.which === 27) {  // Esc
                event.preventDefault();
                this.hideList();
            }
            if (event.which === 13) {  // Enter
                event.preventDefault();
                this.on_confirm();
            }
        });
    }

    setupDefault () {
        let sel = this.options.find( item => item.selected );
        if (sel) {
            this.el_hidden.val(sel.value);
            this.el_text.val(sel.text);
            this.el_cont.addClass("non-empty");
            this.selected = this.options.indexOf(sel);
        } else {
            this.el_hidden.val("");
            this.el_text.val("");
            this.el_cont.removeClass("non-empty");
            this.selected = -1;
        }
        this.initial_value = this.el_hidden.val();
    }
    buildList () {
        this.el_list.text("");
        this.ids_list = [];
        this.options.forEach( (item, id) => {
            let li = this.el_list.appendNew("li", {}, item.text);
            $(li).data("value", item.value);
            this.ids_list.push(id);
        });
    }

    renewOptionsList (options, selected) {
        this.options = [];
        this.selected = -1;

        for (let opt of options) {
            this.options.push({
                value: opt['value'],
                text: opt['text'],
                selected: parseInt(opt['value']) === parseInt(selected)
            });
        }

        this.setupDefault();
        this.buildList();
    }

    sortOptions (fn) {
        this.options.sort(fn);

        this.buildList();
    }

    clearInput () {
        if (this.el_hidden.val()) {
            this.el_hidden.val("");
            this.el_text.val("");
            this.el_cont.removeClass("non-empty");
            this.el_hidden.trigger('change');
            this.initial_value = "";
            this.buildList();
        }
    }

    getIndexByText (text) {
        for (let i = 0; i < this.options.length; i ++) {
            if (this.options[i].text === text) return i;
        }
        return -1;
    }

    showList () {
        this.el_cont.addClass("expanded");
        $.popupManager.register(this.el_cont, () => $(this.el_cont).removeClass("expanded") );
    }
    hideList () {
        this.el_cont.removeClass("expanded");
        $.popupManager.unregister(this.el_cont);
    }
    isListVisible () {
        return this.el_cont.hasClass("expanded");
    }

    isFocused () {
        return this.el_cont.hasClass("focused");
    }

    on_select (target) {
        this.el_hidden.val(target.data("value")).trigger('change');
        this.el_text.val(target.text()).trigger('change');
        this.selected = this.getIndexByText(target.text());

        var domHidden = this.el_hidden.get(0);
        domHidden.dispatchEvent(new Event('change'));
        var domText = this.el_text.get(0)
        domText.dispatchEvent(new Event('change'))

        this.el_cont.addClass("non-empty");
        this.hideList();
    }

    on_confirm () {
        let c_val = this.el_hidden.val();
        let c_opt = this.options.find( item => parseInt(item.value) === parseInt(c_val) );
        if (c_opt) {
            this.el_text.val(c_opt.text);
            this.el_cont.addClass("non-empty");
        } else {
            if (this.ids_list.length === 1 && this.el_text.val().length > 0) {
                let opt = this.options[this.ids_list[0]];

                this.el_hidden.val(opt.value).change();
                this.el_text.val(opt.text).change();
                this.selected = 0;

                this.el_cont.addClass("non-empty");
            } else {
                this.el_text.val("");
                this.el_cont.removeClass("non-empty");
            }
        }
        this.hideList();

        if (this.initial_value !== this.el_hidden.val())
            this.el_hidden.trigger('change');
    }

    on_input () {}
    on_click () {
        if (this._md_state)
            this.hideList();
        else
            this.showList();
        // TODO: Make this work.
    }
    on_arrow () {
        this.el_text.focus();
        this.on_click();
    }
    on_focus () {}
    on_blur () {}
    on_shift (shift) {
        let i = this.ids_list.indexOf(this.selected);
        i = Math.min(Math.max(i + shift, 0), this.ids_list.length);

        this.selected = this.ids_list[i];

        this.el_text.val(this.options[this.selected].text);
        this.el_hidden.val(this.options[this.selected].value);
        this.el_cont.addClass("non-empty");
    }
}

class FreeCustomSelect extends CustomSelect {
    constructor (container) {
        super(container);
        this.el_text.attr("readonly", false);
    }
    on_input (){
        this.el_hidden.val("");
        //let s_old = this.selected;
        this.selected = -1;

        this.saved_value = this.el_text.val();

        if (this.el_text.val().length > 0)
            this.el_cont.addClass("non-empty");
        else
            this.el_cont.removeClass("non-empty");

        let c_text = this.el_text.val();
        let c_opt = this.options.find( item => item.text === c_text );
        if (c_opt) {
            this.el_hidden.val(c_opt.value);
            this.selected = this.getIndexByText(c_text);
        }
        //if (this.selected !== s_old) this.el_hidden.trigger('change');
    }
    on_shift (shift) {
        //let s_old = this.selected;

        let min = this.saved_value === null ? 0 : -1;
        let i = this.ids_list.indexOf(this.selected);
        i = Math.min(Math.max(i + shift, min), this.ids_list.length - 1);
        this.selected = i === -1 ? -1 : this.ids_list[i];

        //if (this.selected !== s_old) this.el_hidden.trigger('change');

        if (~this.selected) {
            this.el_text.val(this.options[this.selected].text);
            this.el_hidden.val(this.options[this.selected].value);
            this.el_cont.addClass("non-empty");
        } else {
            this.el_text.val(this.saved_value);
            this.on_input();
        }
    }
}

class SearchCustomSelect extends CustomSelect {
    constructor (container) {
        super(container);
        this.el_text.attr("readonly", false);
    }

    buildList () {
        let c_text = this.el_text.val();
        let len = c_text.length;

        let opt_pos = this.options.map( (item, id) => ({
            item: item,
            id: id,
            pos: item.text.toLowerCase().indexOf(c_text.toLowerCase()),
        }) );

        if (len !== 0) {
            opt_pos = opt_pos.filter( (obj) => (obj.pos !== -1) );
            opt_pos = opt_pos.sort( (obj1, obj2) => (obj1.pos > obj2.pos) ? 1 : (obj1.pos < obj2.pos) ? -1 : 0 );
        }

        this.el_list.text("");
        this.ids_list = [];

        if (opt_pos.length === 0) return;

        opt_pos.forEach( (obj) => {
            let item = obj.item;
            let pos = obj.pos;

            let li = this.el_list.appendNew("li");
            $(li).data("value", item.value);

            if (pos > 0) li.appendNew("span", {}, item.text.substr(0,pos));
            li.appendNew("span", {style: 'font-weight: 900'}, item.text.substr(pos,len));
            if (pos + len < item.text.length) li.appendNew("span", {}, item.text.substr(pos + len));

            this.ids_list.push(obj.id);
        });
    }

    on_input () {
        this.saved_value = this.el_text.val();
        this.selected = -1;

        if (this.el_text.val().length > 0)
            this.el_cont.addClass("non-empty");
        else {
            this.el_cont.removeClass("non-empty");
            this.el_hidden.val("");
        }

        let c_text = this.el_text.val();
        let c_opt = this.options.find( item => item.text === c_text );
        if (c_opt) {
            this.el_hidden.val(c_opt.value);
        }

        this.buildList();
        this.showList();
    }

    on_focus () {
        this.initial_value = this.el_hidden.val();
        this.buildList();
    }

    on_blur () {
        let c_val = this.el_hidden.val();
        let c_opt = this.options.find( item => parseInt(item.value) === parseInt(c_val) );
        if (c_opt) {
            this.el_text.val(c_opt.text);
            this.el_cont.addClass("non-empty");
        } else {
            this.el_text.val("");
            this.el_cont.removeClass("non-empty");
        }

        if (this.initial_value !== this.el_hidden.val())
            this.el_hidden.trigger('change');
    }

    on_arrow () {
        if (this.el_hidden.val()) {
            this.clearInput();
            // this.el_text.focus();
        } else {
            this.on_click();
        }
    }

    on_shift (shift) {
        //let s_old = this.selected;

        let min = this.saved_value === null ? 0 : -1;
        let i = this.ids_list.indexOf(this.selected);
        i = Math.min(Math.max(i + shift, min), this.ids_list.length - 1);
        this.selected = i === -1 ? -1 : this.ids_list[i];

        //if (this.selected !== s_old) this.el_hidden.trigger('change');

        if (~this.selected) {
            this.el_text.val(this.options[this.selected].text);
            this.el_hidden.val(this.options[this.selected].value);
            this.el_cont.addClass("non-empty");
        } else {
            this.el_text.val(this.saved_value);
            this.on_input();
        }
    }
}

class SearchCustomSelect_Extended extends SearchCustomSelect {
    buildList () {
        let c_text = this.el_text.val();
        let len = c_text.length;
        if (len === 0){
            this.el_list.text("");
            return;
        }
        let limit = 3;

        // Filter options
        let opt_pos = this.options.map( (item, id) => ({
            item: item,
            id: id,
            pos: item.text.toLowerCase().indexOf(c_text.toLowerCase()),
        }) );
        opt_pos = opt_pos.filter( (obj) => (obj.pos !== -1) );
        opt_pos = opt_pos.sort( (obj1, obj2) => (obj1.pos > obj2.pos) ? 1 : (obj1.pos < obj2.pos) ? -1 : 0 );
        opt_pos.splice(limit);

        if (opt_pos.length === 0) return;

        this.el_list.text("");
        this.ids_list = [];
        opt_pos.forEach( (obj) => {
            let item = obj.item;
            let pos = obj.pos;

            let li = this.el_list.appendNew("li", {});
            let cont = li.appendNew("div", {});
            if (item.data.image) cont.appendNew("div", {class: 'image'}).appendNew("img", {src: item.data.image});
            else cont.appendNew("div", {class: 'image'});
            let text = cont.appendNew("div", {class: 'text'});

            if (pos > 0) text.appendNew("span", {}, item.text.substr(0,pos));
            text.appendNew("span", {style: 'font-weight: 900'}, item.text.substr(pos,len));
            if (pos + len < item.text.length) text.appendNew("span", {}, item.text.substr(pos + len));

            $(li).data("value", item.value);

            this.ids_list.push(obj.id);
        });
    }

    on_select (target) {
        target = target.closest('li');
        this.el_hidden.val(target.data("value")).change();
        this.el_text.val(target.find('.text').text()).change();
        this.selected = this.getIndexByText(target.text());

        this.el_cont.addClass("non-empty");
        this.hideList();
    }

    on_blur () {}
}

class FSCustomSelect extends SearchCustomSelect {

    on_input () {
        this.el_hidden.val("");
        //let s_old = this.selected;
        this.selected = -1;

        if (this.el_text.val().length > 0)
            this.el_cont.addClass("non-empty");
        else
            this.el_cont.removeClass("non-empty");

        let c_text = this.el_text.val();
        let c_opt = this.options.find( item => item.text === c_text );
        if (c_opt) {
            this.el_hidden.val(c_opt.value);
            this.selected = this.getIndexByText(c_text);
        }
        //if (this.selected !== s_old) this.el_hidden.trigger('change');

        this.buildList();
    }

    on_blur () {}
}

class MultipleSearchCustomSelect extends SearchCustomSelect {
    constructor (container) {
        super(container);
    }

    setupDefault () {
        let id = this.el_hidden.attr("id");
        let name = this.el_hidden.attr("name");
        this.el_hidden.remove();
        this.el_hidden = this.el_cont.appendNew("select", {"id": id, "name": name, "multiple": true}).css({display: "none"});

        this.selected = 0;
        this.initial_value = [];
        for (let i = 0; i < this.options.length; i ++) {
            let item = this.options[i];
            if (!item.selected) continue;

            this.el_text.before(
                $('<span>').attr("class", "selected-item-text").text(item.text).on('click', () => this.removeSelected(item.value))
            );
            this.el_hidden.appendNew("option", {"value": item.value, "selected": true}).text(item.text);
            this.initial_value.push(i);
        }

        if (this.initial_value.length) {
            this.el_cont.addClass("non-empty");
        } else {
            this.el_cont.removeClass("non-empty");
        }

        this.el_text.val("");
    }

    buildList () {
        super.buildList();

        this.ids_list = this.ids_list.filter(item => !this.options[item].selected);
        let values_list = this.ids_list.map(item => this.options[item].value);

        for (let item of this.el_list.children('li')) {
            if (values_list.indexOf($(item).data("value")) === -1) {
                $(item).remove();
            }
        }
    }

    renewOptionsList (options, selected) {
        this.options = [];
        this.selected = 0;

        selected = selected.map((val, _i, _a) => parseInt(val));

        for (let opt of options) {
            this.options.push({
                value: opt['value'],
                text: opt['text'],
                selected: parseInt(opt['value']) in selected
            });
        }

        this.setupDefault();
        this.buildList();
    }

    clearInput () {
        let opts = this.el_hidden.children('option');
        if (opts.length) {
            opts.remove();
            this.el_text.val("");
            this.el_cont.removeClass("non-empty");
            this.el_hidden.trigger('change');
            this.initial_value = "";
            this.buildList();
        }
    }

    addSelected (value, text) {
        this.options.find( item => parseInt(item.value) === parseInt(value) ).selected = true;

        let opts = this.el_hidden.children('option').map(function () { return parseInt(this.value) }).toArray();
        if (opts.indexOf(parseInt(value)) === -1) {
            this.el_text.before(
                $('<span>').attr("class", "selected-item-text").text(text).on('click', () => this.removeSelected(value))
            );
            this.el_hidden.appendNew("option", {"value": value, "selected": "selected"});
            this.el_hidden.change();
            this.el_text.val("").change();
            this.buildList();
        }
    }

    removeSelected (value) {
        let c_opt = this.options.find( item => parseInt(item.value) === parseInt(value) );
        c_opt.selected = false;
        this.buildList();

        // let opts = this.el_hidden.children('option').map(function () { return parseInt(this.value) }).toArray();

        this.el_box.children('span.selected-item-text').filter(function () { return $(this).text() === c_opt.text }).remove();
        this.el_hidden.find("option[value=" + value + "]").remove();
        this.el_hidden.change();

        if (typeof this.options.find(item => item.selected) === "undefined") {
            this.el_cont.removeClass("non-empty");
        }
    }

    on_select (target) {
        // let c_val = this.el_hidden.val();
        // let c_opt = this.options.find( item => parseInt(item.value) === parseInt(c_val) );
        this.addSelected(target.data("value"), target.text());
        this.el_cont.addClass("non-empty");
        this.hideList();
    }

    on_confirm () {
        if (this.el_text.val().length > 0) {
            let opt = this.options.find(item => item.text === this.el_text.val());

            if (this.ids_list.length === 1) {
                opt = this.options[this.ids_list[0]];
            }

            if (typeof opt !== "undefined") {
                this.addSelected(opt.value, opt.text);
                this.el_cont.addClass("non-empty");
                this.el_text.val("");
            }
        }

        if (this.initial_value !== this.el_hidden.val())
            this.el_hidden.trigger('change');
    }

    on_focus () {
        this.initial_value = this.el_hidden.children('option').map(function () { return parseInt(this.value) }).toArray();
        this.buildList();
    }

    on_blur () {
        let c_val = this.el_hidden.children('option').map(function () { return parseInt(this.value) }).toArray();
        if (c_val.length) {
            this.el_cont.addClass("non-empty");
        } else {
            this.el_cont.removeClass("non-empty");
        }
        this.el_text.val("");
    }

    on_arrow () {
        this.el_text.focus();
        this.on_click();
    }

    on_shift (shift) {
        //let s_old = this.selected;

        let min = this.saved_value === null ? 0 : -1;
        let i = this.ids_list.indexOf(this.selected);
        i = Math.min(Math.max(i + shift, min), this.ids_list.length - 1);
        this.selected = i === -1 ? -1 : this.ids_list[i];

        //if (this.selected !== s_old) this.el_hidden.trigger('change');

        if (~this.selected) {
            this.el_text.val(this.options[this.selected].text);
            this.el_cont.addClass("non-empty");
        } else {
            this.el_text.val(this.saved_value);
            this.on_input();
        }
    }
}

class HorizontalSelect extends AbstractSelect {

    constructor (container) {
        super(container);

        if (!this.el_sel) return;

        this.options = [];

        this.el_sel.find("option").each( (i, opt) => {
            this.options.push({
                value: $(opt).val(),
                text: $(opt).text(),
                data: $(opt).data(),
                selected: $(opt).is(':selected')
            });
        });

        this.el_sel.css({ display: "none" });

        this.el_box = this.el_cont.appendNew("div", {"class": "input-select-box"});
        this.el_list = this.el_box.appendNew("ul", {"class": "input-select-list"}); // noinspection JSUnresolvedFunction

        this.buildList();
        this.el_list.on("click", (event) => this.on_select($(event.target).closest('li')) );

        this.el_sel.change( () => { this.updateOptions() });
    }

    buildList () {
        this.el_list.text("");
        this.ids_list = [];
        this.options.forEach( (item, id) => {
            let li = this.el_list.appendNew("li", {}, item.text);
            $(li).data("value", item.value);
            this.ids_list.push(id);
        });
    }

    updateOptions () {
        let buttons = this.el_list.children();
        let opt_elements = this.el_sel.find("option");

        for (let i = 0; i < opt_elements.length; i ++) {
            let selected = $(opt_elements[i]).is(':selected');
            this.options[i].selected = selected;
            $(buttons[i]).toggleClass("selected", selected);
        }
    }

    on_select (target) {
        target.toggleClass('selected');

        let i = target.index();
        let selected = target.hasClass('selected');

        this.options[i].selected = selected;
        $(this.el_sel.find("option")[i]).prop("selected", selected);
        this.el_sel.change();
    }
}

let select_list = [];

$(function(){

    function process (t) {
        let s;
        if ($(t).hasClass("keep-std"))
            s = new VanillaSelect(t);
        else if ($(t).hasClass("search-select-multiple"))
            s = new MultipleSearchCustomSelect(t);
        else if ($(t).hasClass("free-input-select") && $(t).hasClass("search-list-select"))
            s = new FSCustomSelect(t);
        else if ($(t).hasClass("search-list-select"))
            s = new SearchCustomSelect(t);
        else if ($(t).hasClass("search-list-extended"))
            s = new SearchCustomSelect_Extended(t);
        else if ($(t).hasClass("free-input-select"))
            s = new FreeCustomSelect(t);
        else if ($(t).hasClass("select-horizontal"))
            s = new HorizontalSelect(t);
        else
            s = new CustomSelect(t);
        select_list.push(s);
    }

    function run (root=$(document)) {
        let els = $(root).find("div.input-select");
        els.each( (i, t) => process(t) );
    }
    run();

    let observer = new MutationObserver( function(mutations) {
        for (let mutation of mutations) {
            for (let node of mutation.addedNodes) {
                run(node);
            }
        }
    });
    observer.observe($('#content-wrap')[0], {childList: true, subtree: true});
});

let find_select = function(container) {
    for (let select of select_list) {
        if (select.el_cont.is(container))
            return select;
    }
    return null;
};

export default {
    find: find_select
};
