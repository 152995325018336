require('../main/')

// !!! botan/index.js breaks if it is different
require('../forms/button.sass')
require('../forms/index.sass')
require('../forms/input_text.sass')
require('../forms/radio.sass')
require('../forms/select.sass')
require('../forms/index.js')
require('../forms/input_text.js')
require('../forms/radio.js')
window.select_script = require('../forms/select.js').default

require('../fonts/raleway.css')
require('../login/')
require('../mobile_menu/')
require('../profile_menu/')

import "../main/index.sass";


function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            let cookie = $.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
let csrftoken = getCookie('csrftoken');

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
    beforeSend: function(xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
    }
});

$(function(){
    "use strict";
    let session_uuid_element = $("#session_uuid");
    if (session_uuid_element.length === 0) return;

    let info_sent = false;
    let session_uuid = session_uuid_element.val();
    if (session_uuid === "") return;

    function send_session_info () {
        $.ajax({
            url     : 'https://s.inbicst.ru/session/' + session_uuid + '/set_local_addr/',
            type    : 'GET',
            dataType: "json",
            success : (data) => {
                if (data["status"] === "ok") info_sent = true;
            },
            timeout : 5000
        });
    }

    send_session_info();
    let session_info_interval = window.setInterval(function () {
        if (info_sent) window.removeInterval(session_info_interval);
        else send_session_info();
    }, 600000);
});