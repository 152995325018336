var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (footer_links, menu_links, submenu_links) {pug_html = pug_html + "\u003Cdiv id=\"menu-wrap\"\u003E\u003Cdiv id=\"menu-wrap-inner\"\u003E\u003Cdiv id=\"menu-box\"\u003E\u003Cbutton id=\"menu-close\"\u003EЗакрыть\u003C\u002Fbutton\u003E\u003Cdiv id=\"menu-content\"\u003E\u003Cnav id=\"menu-mobile\"\u003E";
// iterate menu_links
;(function(){
  var $$obj = menu_links;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var link = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([(link.active?"active":"")], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
if (link.active) {
pug_html = pug_html + "\u003Cdiv class=\"sub-item\"\u003E";
// iterate submenu_links
;(function(){
  var $$obj = submenu_links;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var link = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([(link.active?"active":"")], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var link = $$obj[pug_index1];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([(link.active?"active":"")], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var link = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([(link.active?"active":"")], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
if (link.active) {
pug_html = pug_html + "\u003Cdiv class=\"sub-item\"\u003E";
// iterate submenu_links
;(function(){
  var $$obj = submenu_links;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var link = $$obj[pug_index2];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([(link.active?"active":"")], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var link = $$obj[pug_index2];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([(link.active?"active":"")], [true]), false, true)) + "\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fnav\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"menu-footer\"\u003E\u003Cdiv id=\"menu-footer-logo\"\u003E\u003C\u002Fdiv\u003E\u003Cul\u003E";
// iterate footer_links
;(function(){
  var $$obj = footer_links;
  if ('number' == typeof $$obj.length) {
      for (var pug_index3 = 0, $$l = $$obj.length; pug_index3 < $$l; pug_index3++) {
        var link = $$obj[pug_index3];
pug_html = pug_html + "\u003Cli\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index3 in $$obj) {
      $$l++;
      var link = $$obj[pug_index3];
pug_html = pug_html + "\u003Cli\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"footer_links" in locals_for_with?locals_for_with.footer_links:typeof footer_links!=="undefined"?footer_links:undefined,"menu_links" in locals_for_with?locals_for_with.menu_links:typeof menu_links!=="undefined"?menu_links:undefined,"submenu_links" in locals_for_with?locals_for_with.submenu_links:typeof submenu_links!=="undefined"?submenu_links:undefined));;return pug_html;};
module.exports = template;