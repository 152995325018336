import 'jquery'

import './menu.sass'
import menu_html from './menu.pug'

$(function(){
    "use strict";

    let mini_screen;

    let menu_links = [{ref: "/", text: "главная", active: false}];
    $("nav#header-menu").find("a").each( (i, a) => {
        menu_links.push({ref: a.href, text: a.innerText, active: ~a.className.indexOf("active")});
    });

    let submenu_links = [];
    $("div#submenu").find("a").each( (i, a) => {
        submenu_links.push({ref: a.href, text: a.innerText, active: ~a.className.indexOf("active")});
    });

    let footer_links = [];
    $("ul#footer-nav").find("a").each( (i, a) => {
        footer_links.push({ref: a.href, text: a.innerText});
    });

    let menu_box = $(menu_html({
        menu_links: menu_links,
        submenu_links: submenu_links,
        footer_links: footer_links
    }));

    let b = $('body');
    b.append(menu_box);

    let openMenuBtn = $("#mobile-menu");
    let closeMenuBtn = menu_box.find("button#menu-close");

    let showMenu = function(){
        mini_screen = $(window).width() <= 450;

        if (mini_screen){
            b.scrollTop(0);
            b.css("overflow", "hidden");
            menu_box.css("display", "block");
            menu_box.addClass("visible");
        }else{
            menu_box.css("display", "block");
            setTimeout( () => {
                menu_box.addClass("visible");
            }, mini_screen ? 0 : 10);
        }

        closeMenuBtn.focus();
    };

    let hideMenu = function(){
        if (mini_screen){
            b.css("overflow", "visible");
            menu_box.removeClass("visible");
            menu_box.css("display", "none");
        }else{
            menu_box.removeClass("visible");
            setTimeout( () => {
                menu_box.css("display", "none");
            }, 200);
        }

        openMenuBtn.focus();
    };

    openMenuBtn.click(showMenu);
    closeMenuBtn.click(hideMenu);
    menu_box.click( (e) => {
        if (e.target === menu_box[0]) hideMenu(e);
    });
    menu_box.find('#menu-content').find('a').click( (e) => {
        let href_t = e.target.href;
        if (!~href_t.indexOf('#')) return;

        let href_c = window.location.href;
        href_t = href_t.split('#').shift();
        href_c = href_c.split('#').shift();
        if (href_t === href_c) hideMenu();
    });
});
