var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;var locals_for_with = (locals || {});(function (footer_links) {pug_html = pug_html + "\u003Cdiv id=\"user-info-wrap\"\u003E\u003Cdiv id=\"user-info-wrap-inner\"\u003E\u003Cdiv id=\"user-info-box\"\u003E\u003Cbutton id=\"user-info-close\"\u003EЗакрыть\u003C\u002Fbutton\u003E\u003Cdiv id=\"user-info-header\"\u003E\u003Cdiv id=\"user-info-logo\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"user-info-title\"\u003EИНБИКСТ\u003C\u002Fdiv\u003E\u003Cdiv id=\"user-info-subtitle\"\u003EСтуденческий сайт\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"user-info-content\"\u003E\u003Cp\u003E\u003C\u002Fp\u003E\u003Ca class=\"button-g\" href=\"\u002Fprofile\u002F\"\u003EПерейти в профиль\u003C\u002Fa\u003E\u003Cp\u003E\u003C\u002Fp\u003E\u003Ca class=\"button-w\" href=\"\u002Flogout\u002F\"\u003EРазлогиниться\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"user-info-footer\"\u003E\u003Cul\u003E";
// iterate footer_links
;(function(){
  var $$obj = footer_links;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var link = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var link = $$obj[pug_index0];
pug_html = pug_html + "\u003Cli\u003E\u003Ca" + (pug.attr("href", link.ref, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = link.text) ? "" : pug_interp)) + "\u003C\u002Fa\u003E\u003C\u002Fli\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Ful\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";}.call(this,"footer_links" in locals_for_with?locals_for_with.footer_links:typeof footer_links!=="undefined"?footer_links:undefined));;return pug_html;};
module.exports = template;