import 'jquery'
import '../util/jqextend';

import './menu.sass'
import menu_html from './menu.pug'

$(function(){
    "use strict";

    // mobile
    
    let mini_screen;

    let footer_links = [];
    $("ul#footer-nav").find("a").each( (i, a) => {
        footer_links.push({ref: a.href, text: a.innerText});
    });

    let menu_box = $(menu_html({
        footer_links: footer_links
    }));

    let b = $('body');
    b.append(menu_box);

    let showMobileMenuBtn = $("#mobile-user-info");
    let hideMobileMenuBtn = $("#user-info-close");

    let showMobileMenu = function(){
        mini_screen = $(window).width() <= 450;

        if (mini_screen){
            b.scrollTop(0);
            b.css("overflow", "hidden");
            menu_box.addClass("visible");
        }else{
            menu_box.css("display", "block");
            setTimeout( () => {
                menu_box.addClass("visible");
            }, mini_screen ? 0 : 10);
        }

        hideMobileMenuBtn.focus()
    };

    let hideMobileMenu = function(){
        if (mini_screen){
            b.css("overflow", "visible");
            menu_box.removeClass("visible");
        }else{
            menu_box.removeClass("visible");
            setTimeout( () => {
                menu_box.css("display", "none");
            }, 200);
        }

        showMobileMenuBtn.focus()
    };

    showMobileMenuBtn.click(showMobileMenu);
    hideMobileMenuBtn.click(hideMobileMenu);
    menu_box.click( (e) => {
        if (e.target === menu_box[0]) hideMobileMenu(e);
    });

    // desktop

    let showDesktopMenuBtn = $("#header-user-info");

    let showDesktopMenu = function(event) {
        let menu = $("#user-menu-box");
        menu.css("display", "inline-block");
        setTimeout( () => {
            menu.css('min-width', showDesktopMenuBtn.width() + 40);
            menu.addClass("visible");
            menu.find("a").first().focus();
        }, 10);
        $.popupManager.register(menu, hideDesktopMenu);
        event.stopPropagation();
    };

    let hideDesktopMenu = function() {
        let menu = $("#user-menu-box");
        menu.removeClass("visible");
        setTimeout( () => {
            menu.css("display", "none");
        }, 100);
        $.popupManager.unregister(menu);
    };

    showDesktopMenuBtn.click(showDesktopMenu);
});
