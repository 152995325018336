import $ from 'jquery'
"use strict";

$.fn.extend({
    appendNew: function (tag, options, innerText) {
        let el = $("<"+tag+"/>");

        for (let opt in options) {
            if (!Object.prototype.hasOwnProperty.call(options, opt)) continue;
            el.attr(opt, options[opt]);
        }
        if (innerText) el.text(innerText);

        $(this).append(el);
        
        return el;
    },

    insert_options: function (options, text_f=null, id_f=null, selected_id=null) {
        let selects = $(this).filter('select');

        options.forEach( (option, id) => {
            let text = text_f === null ? option : text_f(option);
            id = id_f === null ? id : id_f(option);
            let opt = $('<option>').attr('value', id).text(text);
            if (id === selected_id) opt.attr('selected', 'selected');
            selects.append(opt);
        });

        return $(this);
    },

    serializeObject: function () {
        let forms = $(this).filter('form');

        let obj = {};

        for (let form of forms) {
            let data = $(form).serializeArray();
            for (let el of data) {
                obj[el.name] = el.value;
            }
        }

        return obj;
    },

    // Based on https://stackoverflow.com/questions/5984928/
    animateMoveBy: function (val, duration=1000) {

        function cssInt($e, id) {
            return parseInt($e.css(id), 10);
        }

        if (this.parent().length > 1) {
            throw Error('All the elements in set should be siblings');
        }
        if (this.last().index() - this.first().index() !== this.length - 1) {
            throw Error('Element set should be continuous');
        }

        if (val === 0) return this;

        let $target = $();
        let $c = val < 0 ? this.first() : this.last();
        for (let i = 0; i < Math.abs(val); i ++) {
            $c = val < 0 ? $c.prev() : $c.next();
            $target = $target.add($c);
        }

        let $set1, $set2;
        if (val < 0) {
            $set1 = $target;
            $set2 = this;
        } else {
            $set1 = this;
            $set2 = $target;
        }
        let $set3 = $set2.last().nextAll();

        let mb_prev = cssInt($set1.first().prev(), "margin-bottom");
        if (isNaN(mb_prev)) mb_prev = 0;
        let mt_next = cssInt($set2.last().next(), "margin-top");
        if (isNaN(mt_next)) mt_next = 0;

        let mt_1 = cssInt($set1.first(), "margin-top");
        let mb_1 = cssInt($set1.last(), "margin-bottom");
        let mt_2 = cssInt($set2.first(), "margin-top");
        let mb_2 = cssInt($set2.last(), "margin-bottom");

        let h1 = $set1.last().offset().top + $set1.last().outerHeight() - $set1.first().offset().top;
        let h2 = $set2.last().offset().top + $set2.last().outerHeight() - $set2.first().offset().top;

        let move1 = h2 + Math.max(mb_2, mt_1) + Math.max(mb_prev, mt_2) - Math.max(mb_prev, mt_1);
        let move2 = -h1 - Math.max(mb_1, mt_2) - Math.max(mb_prev, mt_1) + Math.max(mb_prev, mt_2);
        let move3 = Math.max(mb_prev, mt_2) + Math.max(mb_2, mt_1) + Math.max(mb_1,mt_next) -
                    Math.max(mb_prev, mt_1) - Math.max(mb_1, mt_2) - Math.max(mb_2,mt_next);

        $set1.css('position', 'relative');
        $set2.css('position', 'relative');
        $set3.css('position', 'relative');
        $set1.animate({'top': move1}, {duration: duration});
        $set3.animate({'top': move3}, {duration: duration});
        $set2.animate({'top': move2}, {duration: duration, complete: function() {
            $set1.insertAfter($set2.last());
            $set1.css({'position': 'static', 'top': 0});
            $set2.css({'position': 'static', 'top': 0});
            $set3.css({'position': 'static', 'top': 0});
        } });

        return this
    },
});


$.popupManager = {
    popups: [],
    register: function(element, hide) {
        this.popups.push({element: element, hide: hide, mouse_out: false});
    },
    unregister: function(element) {
        for (let i = this.popups.length - 1; i >= 0 ; i -- ) {
            if (this.popups[i].element !== element) continue;
            this.popups.splice(i,1);
        }
    },
    mouse_down: function(element) {
        for (let i = this.popups.length - 1; i >= 0 ; i -- ) {
            let popup = this.popups[i];
            popup.mouse_out = !(popup.element.is(element) || $(popup.element).has(element).length);
        }
    },
    mouse_click: function(element) {
        for (let i = this.popups.length - 1; i >= 0 ; i -- ) {
            let popup = this.popups[i];
            if (popup.element.is(element) || $(popup.element).has(element).length || !popup.mouse_out) continue;
            popup.hide();
            this.popups.splice(i,1);
        }
    }
};
document.addEventListener("click", function(event){$.popupManager.mouse_click(event.target)});
document.addEventListener("mousedown", function(event){$.popupManager.mouse_down(event.target)});
