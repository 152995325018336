import 'jquery'
import '../forms'

import './login.sass'
import login_html from './login.pug'

$(function(){
    "use strict";

    let small_screen;

    let login_box = $(login_html());

    let wrapper = $("#page-wrap"),
        header = wrapper.find(">header"),
        is_header_big = (header.attr('id') === "header-big");

    let error_hide_timeout = null;

    let showLoginForm = function(){
        small_screen = $(window).width() <= 900;
        $(document.body).scrollTop(0);

        if (small_screen || !is_header_big) {
            $(document.body).append(login_box);
            $(document.body).css("overflow", "hidden");
            wrapper.children(":not(#login-box)").css("filter", "blur(15px)");
        } else {
            header.append(login_box);
            header.children(":not(#login-wrap)").css("filter", "blur(10px)");
        }

        login_box.find("#login-cancel").click(hideLoginForm);
        login_box.find('button#login-form-btn').click(login);
        login_box.find('#login-form').keypress( event => {
            if (event.which === 13) {
                login();
            }
        });

        login_box.show();
        login_box.find('#login-input-login').focus();
    };

    let hideLoginForm = function(){
        login_box.hide();
        if (small_screen || !is_header_big) {
            wrapper.children(":not(#login-box)").css("filter", "none");
            $(document.body).css("overflow", "visible");
        } else {
            header.children(":not(#login-wrap)").css("filter", "none");
        }
        login_box.remove()
    };

    let show_login_error = function(text) {
        let errors_container = $("div#login-errors");
        if (error_hide_timeout !== null) {
            clearTimeout(error_hide_timeout);
            errors_container.addClass("highlighted");
            setTimeout(
                () => errors_container.removeClass("highlighted"),
                200
            );
        } else {
            errors_container.text(text).fadeIn(300);
        }
        error_hide_timeout = setTimeout( () => {
            error_hide_timeout = null;
            errors_container.fadeOut(300);
        }, 5000);
    }

    let login = function(){
        $.ajax({
            type: "POST",
            url: "/login/",
            dataType: "json",
            data: {
                email: $("input#login-input-login").val().trim(),
                password: $("input#login-input-password").val()
            },
            success: data => {
                if (data.status === "ok") {
                    if (data.redirect_url)
                        window.location = data.redirect_url
                    else
                        window.location.reload();
                } else {
                    show_login_error(data.error);
                }
            },
            error: () => {
                show_login_error("Запрос к серверу не удался");
            }
        });
    };

    let check_hash = function () {
        let hash = window.location.hash.substr(1);
        if (hash === 'login') showLoginForm();
    };

    check_hash();
    $(window).on('hashchange', check_hash);

    $("#header-login").click(showLoginForm);
    $("#mobile-login").click(showLoginForm);
});