"use strict";
import autosize from 'autosize'

$(function(){

    function process (t) {
        let el_cont = $(t);
        let inp = el_cont.find("input");
        if (!inp.length){
            inp = el_cont.find("textarea");
            autosize(inp);
        }

        if (inp.is(":focus")) el_cont.addClass("focused");
        if (inp.val().length > 0) el_cont.addClass("non-empty");

        inp.on("focus", () => {
            el_cont.addClass("focused");
        });

        inp.on("blur", () => {
            el_cont.removeClass("focused");
        });

        inp.on("input", () => {
            if (inp.val().length > 0) el_cont.addClass("non-empty");
            else el_cont.removeClass("non-empty");
        });

        if (el_cont.hasClass('with-clean-btn')) {
            let clean_btn = el_cont.appendNew("button", {"type": "button", "class": 'clean-btn'}, 'x');
            clean_btn.click( event => {
                event.stopPropagation();
                inp.val("").focus();
                el_cont.removeClass("non-empty");
                return false;
            });
        }
    }

    function run (root=$(document)) {
        let els = $(root).find("div.input-text");
        els.each( (i, t) => process(t) );
    }
    run();

    let observer = new MutationObserver( function(mutations) {
        for (let mutation of mutations) {
            for (let node of mutation.addedNodes) {
                run(node);
            }
        }
    });
    observer.observe($('#content-wrap')[0], {childList: true, subtree: true});
});